<template>
  <div
    class="grid mt-3 surface-card shadow-2 p-3 border-1 border-50 border-round"
  >
    <div class="col-12"><h3>Mailings</h3></div>

    <DataTable
      class="col-12"
      :value="formattedMailings"
      responsiveLayout="scroll"
      :loading="loading"
      :lazy="true"
      :paginator="true"
      :rows-per-page-options="rowSelectionOptions"
      v-model:rows="rowsToShow"
      :totalRecords="totalRecords"
      @sort="onSort($event)"
      @page="onPage($event)"
    >
      <template #header>
        <div class="text-right flex flex-row-reverse">
          <div class="flex w-4rem h-2rem">
            <Button
              type="button"
              class="p-button-primary mx-2"
              @click="toggle"
              icon="pi pi-eye"
              aria:haspopup="true"
              aria-controls="overlay_panel"
            />
            <OverlayPanel ref="op" appendTo="body">
              <div
                v-for="optionalColumn in allColumns"
                :key="optionalColumn.field"
                class="flex-1 px-2 py-2"
              >
                <Checkbox
                  name="city"
                  :value="optionalColumn"
                  :binary="true"
                  v-model="optionalColumn.toggle"
                />
                <label class="pl-2">{{ optionalColumn.header }}</label>
              </div>
            </OverlayPanel>
          </div>
        </div>
      </template>

      <Column field="name" header="Name" :sortable="true"></Column>
      <Column
        field="description"
        header="Description"
        :sortable="true"
      ></Column>

      <Column
        v-for="column in selectedColumns"
        :key="column.field"
        :field="column.field"
        :header="column.header"
        :sortable="column.sortable"
      ></Column>

      <Column header="">
        <template #body="slotProps">
          <Button
            label="View Mailing"
            @click="
              $router.push({
                name: 'mailing-overview',
                params: { id: slotProps.data.id },
              })
            "
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import COMailing from "@/models/CampaignOverviewMailing";
import QueryBuilderService from "@/service/QueryBuilderService";
import numeral from "numeral";
import { useAppStore } from "@/stores/app";
export default {
  name: "MailingTable",
  setup() {
    const appStore = useAppStore();
    return { appStore };
  },
  data() {
    return {
      mailings: [],
      formattedMailings: [],
      allColumns: [
        {
          key: "contacts",
          header: "Contacts",
          sortable: false,
          field: "contacts",
          toggle: true,
          numberFormat: true,
        },
        {
          key: "web_visits",
          header: "Web Visits",
          sortable: false,
          field: "webVisits",
          toggle: true,
        },
        {
          key: "web_responses",
          header: "Web Responses",
          sortable: false,
          field: "webResponses",
          toggle: true,
        },
        {
          key: "web_response_percentage",
          header: "Web Response %",
          sortable: false,
          field: "webResponsePercentage",
          toggle: true,
        },
        {
          key: "qr_visits",
          header: "QR Visits",
          sortable: false,
          field: "qrVisits",
          toggle: true,
        },
        {
          key: "qr_responses",
          header: "QR Responses",
          sortable: false,
          field: "qrResponses",
          toggle: true,
        },
        {
          key: "qr_response_percentage",
          header: "QR Response %",
          sortable: false,
          field: "qrResponsePercentage",
          toggle: true,
        },
        {
          key: "email_visits",
          header: "Email Visits",
          sortable: false,
          field: "emailVisits",
          toggle: false,
        },
        {
          key: "email_responses",
          header: "Email Responses",
          sortable: false,
          field: "emailResponses",
          toggle: false,
        },
        {
          key: "email_response_percentage",
          header: "Email Response %",
          sortable: false,
          field: "emailResponsePercentage",
          toggle: false,
        },
      ],
      totalRecords: 0,
      loading: false,
      currentPage: 1,
      rowSelectionOptions: [10, 20],
      rowsToShow: 10,
      sortBy: "-mailing_date",
    };
  },
  computed: {
    selectedColumns() {
      return this.allColumns.filter((item) => item.toggle);
    },
    campaign() {
      return this.appStore.currentCampaign;
    },
  },
  watch: {
    rowsToShow() {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    formatMailings() {
      let mailings = [];

      for (let mailing of this.mailings) {
        let web_stats = mailing.stats.channel_stats.find(
          (x) => x.initial_response_channel.toLowerCase() === "web"
        );
        let qr_stats = mailing.stats.channel_stats.find(
          (x) => x.initial_response_channel.toLowerCase() === "qr"
        );
        let email_stats = mailing.stats.channel_stats.find(
          (x) => x.initial_response_channel.toLowerCase() === "email"
        );

        //Skip if no responses yet
        // if (!web_stats && !qr_stats) {
        //   continue;
        // }

        let newMailing = {
          id: mailing.id,
          name: mailing.name,
          description: mailing.description,
          contacts: numeral(mailing.total_mailed).format("0,0"),
          webVisits: web_stats && web_stats.visits ? web_stats.visits : 0,
          webResponses: web_stats && web_stats.leads ? web_stats.leads : 0,
          webResponsePercentage:
            web_stats && web_stats.percentage ? web_stats.percentage : 0,
          qrVisits: qr_stats && qr_stats.visits ? qr_stats.visits : 0,
          qrResponses: qr_stats && qr_stats.leads ? qr_stats.leads : 0,
          qrResponsePercentage:
            qr_stats && qr_stats.percentage ? qr_stats.percentage : 0,
          emailVisits:
            email_stats && email_stats.visits ? email_stats.visits : 0,
          emailResponses:
            email_stats && email_stats.leads ? email_stats.leads : 0,
          emailResponsePercentage:
            email_stats && email_stats.percentage ? email_stats.percentage : 0,
        };
        mailings.push(newMailing);
      }

      this.formattedMailings = mailings;
    },
    toggle(event) {
      this.$refs.op.toggle(event);
    },

    loadData() {
      this.loading = true;
      console.log(this.campaign);
      let qb = new QueryBuilderService(
        COMailing.where("campaign_id", this.campaign.id),
        {
          sort: this.sortBy,
          limit: this.rowsToShow,
          page: this.currentPage,
        }
      );

      qb.buildQuery()
        .get()
        .then((response) => {
          this.mailings = response.data;
          this.totalRecords = response.meta.total;
          this.formatMailings();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something Went Wrong. Please Try Again",
            life: 2000,
          });
        })
        .then(() => (this.loading = false));
    },
    onSort($event) {
      if (Object.prototype.hasOwnProperty.call($event, "sortOrder")) {
        if ($event.sortOrder === -1) {
          this.sortBy = "-" + this.sortBy;
        } else {
          this.sortBy = $event.sortField;
        }
        this.loadData();
      }
    },
    onPage(event) {
      this.currentPage = event.page + 1;
      this.loadData();
    },
  },
};
</script>

<style scoped></style>
